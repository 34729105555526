import React from 'react';
import classNames from 'classnames';

const FooterSocial = ({
                        className,
                        ...props
                      }) => {

  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href="https://api.whatsapp.com/send?phone=551532118246">
            <svg
              width="24"
              height="24"
              viewBox="0 0 40 40"
              xmlns="http://www.w3.org/2000/svg">
              <title>Whatsapp</title>
              <g>
                <circle cx="12.145" cy="3.892" r="1"/>
                <path className="st0" d="M10.7,32.8l0.6,0.3c2.5,1.5,5.3,2.2,8.1,2.2l0,0c8.8,0,16-7.2,16-16c0-4.2-1.7-8.3-4.7-11.3
                c-3-3-7-4.7-11.3-4.7c-8.8,0-16,7.2-15.9,16.1c0,3,0.9,5.9,2.4,8.4l0.4,0.6l-1.6,5.9L10.7,32.8z"/>
                <path className="icon_logo_white" d="M32.4,6.4C29,2.9,24.3,1,19.5,1C9.3,1,1.1,9.3,1.2,19.4c0,3.2,0.9,6.3,2.4,9.1L1,38l9.7-2.5
        c2.7,1.5,5.7,2.2,8.7,2.2l0,0c10.1,0,18.3-8.3,18.3-18.4C37.7,14.4,35.8,9.8,32.4,6.4z M19.5,34.6L19.5,34.6c-2.7,0-5.4-0.7-7.7-2.1
        l-0.6-0.3l-5.8,1.5L6.9,28l-0.4-0.6c-4.4-7.1-2.3-16.5,4.9-20.9s16.5-2.3,20.9,4.9s2.3,16.5-4.9,20.9C25.1,33.8,22.3,34.6,19.5,34.6
        z M28.3,23.5L27.2,23c0,0-1.6-0.7-2.6-1.2c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.5,0.1-0.7,0.2l0,0c0,0-0.1,0.1-1.5,1.7
        c-0.1,0.2-0.3,0.3-0.5,0.3h-0.1c-0.1,0-0.3-0.1-0.4-0.2l-0.5-0.2l0,0c-1.1-0.5-2.1-1.1-2.9-1.9c-0.2-0.2-0.5-0.4-0.7-0.6
        c-0.7-0.7-1.4-1.5-1.9-2.4l-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.4c0-0.2,0-0.4,0.1-0.5c0,0,0.4-0.5,0.7-0.8c0.2-0.2,0.3-0.5,0.5-0.7
        c0.2-0.3,0.3-0.7,0.2-1c-0.1-0.5-1.3-3.2-1.6-3.8c-0.2-0.3-0.4-0.4-0.7-0.5h-0.3c-0.2,0-0.5,0-0.8,0c-0.2,0-0.4,0.1-0.6,0.1
        l-0.1,0.1c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.7,0.9-1.1,2-1.1,3.1l0,0c0,0.8,0.2,1.6,0.5,2.3l0.1,0.3
        c0.9,1.9,2.1,3.6,3.7,5.1l0.4,0.4c0.3,0.3,0.6,0.5,0.8,0.8c2.1,1.8,4.5,3.1,7.2,3.8c0.3,0.1,0.7,0.1,1,0.2l0,0c0.3,0,0.7,0,1,0
        c0.5,0,1.1-0.2,1.5-0.4c0.3-0.2,0.5-0.2,0.7-0.4l0.2-0.2c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.4-0.4,0.5-0.6c0.2-0.4,0.3-0.9,0.4-1.4
        c0-0.2,0-0.5,0-0.7C28.6,23.7,28.5,23.6,28.3,23.5z"/>
                <path className="icon_logo_white" d="M56.2,23.5h-0.1l-2.7-10h-2.1l-2.6,10h-0.1l-2.3-10h-2.5l3.6,13.4h2.3l2.7-9.5h0.1l2.7,9.5h2.2l3.5-13.3h-2.4
        L56.2,23.5z M68.4,16.5c-0.7,0-1.4,0.2-2,0.5c-0.5,0.3-1,0.8-1.2,1.3h-0.2v-5.5h-2.3v14.1h2.3V21c0-0.7,0.2-1.3,0.7-1.8
        c0.5-0.5,1.1-0.7,1.8-0.7c0.6,0,1.2,0.2,1.6,0.6c0.4,0.5,0.6,1.1,0.5,1.7v6H72v-6.5c0.1-1-0.3-2-0.9-2.8
        C70.4,16.8,69.4,16.5,68.4,16.5L68.4,16.5z M78.4,16.5c-1.1,0-2.1,0.2-3,0.8c-0.7,0.5-1.2,1.3-1.3,2.1h2.2c0.1-0.3,0.4-0.6,0.7-0.8
        c0.4-0.2,0.9-0.3,1.3-0.3c0.5,0,1,0.1,1.4,0.4c0.3,0.3,0.5,0.7,0.5,1.2v0.9l-2.6,0.2c-1,0-2,0.3-2.9,0.9c-0.7,0.5-1,1.3-1,2.2
        c0,0.8,0.3,1.6,0.9,2.2c0.7,0.6,1.5,0.9,2.4,0.8c0.6,0,1.2-0.1,1.8-0.4c0.5-0.3,0.9-0.7,1.2-1.1h0.2v1.4h2.2v-7
        c0.1-1-0.3-1.9-1.1-2.5C80.5,16.7,79.4,16.4,78.4,16.5L78.4,16.5z M80.2,23.2c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6
        c-0.5,0-0.9-0.1-1.3-0.4c-0.3-0.2-0.5-0.6-0.5-1c0-0.8,0.6-1.3,1.9-1.4l2.3-0.1L80.2,23.2L80.2,23.2z M87.8,14.3h-2.3v2.5h-1.7v1.8
        h1.7v5.5c-0.1,0.8,0.2,1.6,0.8,2.1c0.7,0.5,1.6,0.7,2.5,0.6c0.4,0,0.8,0,1.2-0.1V25c-0.3,0-0.5,0-0.8,0c-0.4,0-0.8-0.1-1.1-0.3
        c-0.2-0.3-0.4-0.7-0.3-1.1v-5H90v-1.8h-2.2C87.8,16.8,87.8,14.3,87.8,14.3z M97,21.1l-1.8-0.4c-0.4-0.1-0.8-0.2-1.1-0.5
        c-0.2-0.2-0.4-0.4-0.4-0.7c0-0.4,0.2-0.7,0.5-0.9c0.4-0.2,0.9-0.4,1.3-0.3c0.5,0,0.9,0.1,1.3,0.3c0.3,0.2,0.5,0.5,0.6,0.9h2.2
        c0-0.9-0.5-1.7-1.2-2.2c-0.9-0.5-1.9-0.8-2.9-0.8c-1,0-2.1,0.3-2.9,0.9c-0.7,0.5-1.1,1.4-1.1,2.2c0,0.7,0.2,1.3,0.7,1.8
        c0.6,0.5,1.4,0.9,2.2,1l1.8,0.4c0.9,0.2,1.4,0.6,1.4,1.2c0,0.4-0.2,0.7-0.5,0.9c-0.4,0.2-0.9,0.4-1.4,0.3c-0.5,0-1-0.1-1.4-0.3
        c-0.3-0.2-0.6-0.5-0.7-0.9h-2.3c0,0.9,0.5,1.7,1.3,2.2c0.9,0.6,2,0.8,3,0.8c1.1,0,2.2-0.3,3.1-0.9c0.8-0.5,1.2-1.4,1.2-2.4
        c0-0.7-0.2-1.3-0.7-1.8C98.4,21.5,97.7,21.2,97,21.1L97,21.1z M105.4,13.5l-4.8,13.4h2.4l1.2-3.4h5l1.1,3.4h2.6l-4.8-13.4H105.4
        L105.4,13.5z M104.8,21.6l1.8-5.5h0.2l1.8,5.5H104.8L104.8,21.6z M120,16.6c-0.7,0-1.3,0.2-1.9,0.5c-0.5,0.3-1,0.8-1.3,1.4h-0.2
        v-1.7h-2.2v13.4h2.3v-4.8h0.2c0.3,0.5,0.7,1,1.2,1.3c0.6,0.3,1.2,0.5,1.9,0.4c1.2,0,2.3-0.5,3-1.4c0.8-1.1,1.2-2.5,1.1-3.8
        c0.1-1.4-0.3-2.7-1.1-3.8C122.3,17,121.1,16.5,120,16.6L120,16.6z M121.1,24.2c-0.8,1-2.4,1.2-3.4,0.3c-0.1-0.1-0.2-0.2-0.3-0.3
        c-0.5-0.7-0.7-1.5-0.7-2.4c0-0.9,0.2-1.7,0.7-2.4c0.9-1,2.4-1.2,3.4-0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0.7,0.7,1.6,0.7,2.4
        C121.8,22.7,121.6,23.5,121.1,24.2L121.1,24.2z M134.6,18c-0.7-0.9-1.9-1.5-3.1-1.4c-0.7,0-1.3,0.2-1.9,0.5c-0.5,0.3-1,0.8-1.3,1.4
        h-0.2v-1.7H126v13.4h2.3v-4.8h0.2c0.2,0.5,0.6,1,1.2,1.3c0.6,0.3,1.2,0.5,1.9,0.4c1.2,0.1,2.3-0.5,3.1-1.4c0.8-1.1,1.2-2.5,1.1-3.8
        C135.8,20.4,135.4,19.1,134.6,18z M132.7,24.2c-0.8,1-2.4,1.2-3.4,0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.7-0.7-1.5-0.7-2.4
        c0-0.9,0.2-1.7,0.7-2.4c0.9-1,2.4-1.2,3.4-0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0.7,0.7,1.6,0.7,2.4C133.4,22.7,133.1,23.5,132.7,24.2
        L132.7,24.2z"/>
              </g>
            </svg>
          </a>
        </li>
        <li>
          <a href="https://facebook.com/">
            <svg
              width="24"
              height="24"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg">
              <title>Facebook</title>
              <path
                d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z"/>
            </svg>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/lofficina_import">
            <svg
              width="24"
              height="24"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg">
              <title>Instagram</title>
              <g>
                <circle cx="12.145" cy="3.892" r="1"/>
                <path
                  d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"/>
                <path
                  d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z"/>
              </g>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default FooterSocial;